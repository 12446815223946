<template>
  <div class="hide-job" @click="handleHidenJob" :class="{ hidden: isHiden }">
    <i class="fa-regular fa-ban"></i>
    <p>{{ $t('hide job') }}</p>
  </div>
</template>

<script setup lang="ts">
import { useManageJobStore } from '@/store/manageJob'
const props = defineProps(['JobId', 'type'])
import { useAuthStore } from '@/store/auth'
const emit = defineEmits(['fetchData'])
const Auth = useAuthStore()
const store = useManageJobStore()
const isHiden = ref<any>(false)

const handleHidenJob = async () => {
  if (!Auth.isAuth) {
    Auth.LoginModal(true)
  } else {
    try {
      if (!props.type) {
        isHiden.value = !isHiden.value
      }
      await store.hideJob(props.JobId)
      emit('fetchData', props.JobId)

      Auth.LoginModal(false)
    } catch (error) {
      console.error('Error Hiden job:', error)
    }
  }
}
</script>

<style scoped lang="scss">
.hide-job {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  white-space: nowrap;
  gap: 0.25rem;
  font-size: var(--sm-font);
  i {
    color: var(--danger-color);
  }
  &:hover {
    color: var(--danger-color);
    i {
      font-weight: 700;
    }
  }
  &.hidden {
    color: var(--danger-color);
    i {
      font-weight: 700;
    }
  }
}
</style>
